'use client'
import { createApolloClient } from '@/lib/apollo'
import { ApolloProvider } from '@apollo/client'
import Metrics from './metrics'
import { AuthProvider } from '@/contexts/AuthContext'
import { Toaster } from '@/components/ui/sonner'

export default function Main({ children }: { children: React.ReactNode }) {
  const apolloClient = createApolloClient()
  return (
    <ApolloProvider client={apolloClient}>
      <AuthProvider>
        <main className="min-h-screen flex flex-col items-center">
          {children}
          <Metrics />
          <Toaster richColors />
        </main>
      </AuthProvider>
    </ApolloProvider>
  )
}
