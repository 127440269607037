import GoogleAnalytics from './GoogleAnalytics'
import MicrosoftClarity from './MicrosoftClarity'
import Hotjar from './Hotjar'
import { IS_DEV } from '@/lib/conts'
const Metrics = () =>
  IS_DEV ? null : (
    <>
      <GoogleAnalytics />
      <MicrosoftClarity />
      <Hotjar />
    </>
  )

export default Metrics
