import { onError } from '@apollo/client/link/error'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'
import { setContext } from '@apollo/client/link/context'
import { RetryLink } from '@apollo/client/link/retry'
import { ApolloClient, from, InMemoryCache } from '@apollo/client'
import { supabaseClient } from '@/lib/supabase'
import { GQL_SERVER_URI } from './conts'
import { ErrorType } from '@/generated/graphql'
import { isError } from './errors'
import { redirectToLogin } from './auth'

const uploadHttpLink = createUploadLink({
  uri: GQL_SERVER_URI,
})

const errorLink = onError((error) => {
  console.log('error', error)
  // Redirect to login if unauthenticated
  // if (isError(error, ErrorType.Unauthenticated)) {
  //   redirectToLogin()
  // }
})

const authLink = setContext(async (_, { headers }) => {
  const auth = await supabaseClient.auth.getSession()
  const token = auth.data.session?.access_token
  const Apikey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY

  return {
    headers: {
      ...headers,
      // Add auth headers if available
      authorization: token ? `Bearer ${token}` : '',
      Apikey,
    },
  }
})

const retryLink = new RetryLink({
  attempts: {
    // GQL errors don't trigger retries, only network errors (according to the docs).
    retryIf: (error) => {
      if (typeof error !== 'object') {
        return false
      }

      // Internet is offline or server is down.
      return error?.message === 'Failed to fetch'
    },
  },
})

export const createApolloClient = () =>
  new ApolloClient({
    link: from([authLink, errorLink, retryLink, uploadHttpLink]),
    cache: new InMemoryCache({
      // Cache policies defined by type, e.g. how to merge incoming data with existing data.
      typePolicies: {},
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'no-cache',
      },
      watchQuery: {
        fetchPolicy: 'no-cache',
      },
      mutate: {
        fetchPolicy: 'no-cache',
      },
    },
  })
