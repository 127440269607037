export const IS_DEV = process.env.NODE_ENV === 'development'
export const IS_PROD = process.env.NODE_ENV === 'production'

export const GQL_SERVER_URI =
  process.env.NEXT_PUBLIC_GQL_SERVER_URI ??
  `https://graphql.careerparty.workers.dev`

export const SUPPORT_EMAIL = 'support@careerparty.io'
export const SUPPORT_EMAIL_LINK = `mailto:${SUPPORT_EMAIL}`

export const SUPABASE_RESUME_UPLOAD_BUCKET_NAME = 'resumes'
export const SUPABASE_PROOF_UPLOAD_BUCKET_NAME = 'proofs'
export const SUPABASE_RESUMEREVIEWS_UPLOAD_BUCKET_NAME = 'resumereviews'

export const STRIPE_PUBLIC_KEY = process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY ?? ''
export const STRIPE_CUSTOMER_PORTAL_URL =
  process.env.NEXT_PUBLIC_STRIPE_CUSTOMER_PORTAL_URL ?? ''

export const MIN_PASSWORD_LENGTH = 8
